import React from "react"
import { images } from "../../../static/index"
import { Box } from "@mui/system"
import Hero from "./Hero"

const ContactUs = React.lazy(() => import("../shared/Footer/ContactUs"))
const PortfolioCards = React.lazy(() => import("./PortfolioCards"))

import {
  Trackhero,
  Coworkify,
  Driverbee,
  MathStarter,
  Comet,
  Healthtech,
  Klixpert,
  Martech,
  Pixeleto,
  Rating,
  Strada,
  Ugami,
  Vector,
  Ohmconnect,
  Aimexpert,
} from "../Homepage/WorkSwiper/SVGs"
import "./Portfolio.scss"
import Head from "../utils/Head"

function Portfolio() {
  const cardData = [
    {
      id: 1,
      bgImg: images.portfolio.PortfolioTrackheroBg,
      img: <Trackhero />,
      cardHeading: "TrackHero",
      cardIndustry: [
        "Automotive",
        "Experiential Events",
        "Fleet Management",
        "B2B",
      ],
      cardTechnologies: [
        "Django",
        "PostgreSQL",
        "ReactJs",
        "Typescript",
        "WebSockets",
        "Redux",
        "AWS",
      ],
      cardText: "Experiential Events & Fleet Management Platform",
      cardBtn: "Read More",
      cardClr: "#601cff",

      url: "/case-study/experiential-events-&-fleet-management-platform/",
      className: "col-md-12 col-6 card-sm",
      xClass: "object-right",
      colSide: "right",
    },
    {
      id: 2,
      bgImg: images.portfolio.PortfolioDriverBg,
      img: <Driverbee />,
      cardHeading: "DriverBee",
      cardIndustry: ["Transportation & Logistics"],
      cardTechnologies: [
        "Python",
        "Django",
        "PostgreSQL",
        "ReactJS",
        "Bootstrap",
        "AWS",
      ],
      cardText: "Shipment Management",
      cardBtn: "Read More",
      url: "/case-study/shipment-management/",
      cardClr: "#FFB800",
      className: "col-md-12 col-6 card-sm",
      xClass: "object-right",
      colSide: "right",
    },
    {
      id: 3,
      bgImg: images.portfolio.PortfolioDriverBg,
      img: <MathStarter />,
      cardHeading: "MathStarter",
      cardIndustry: ["E-learning", "Education", "Ed-Tech"],
      cardTechnologies: [
        "Redux",
        "NodeJs",
        "ReactJS",
        "Material UI",
        "Typescript",
      ],
      cardText: "Simplifying Math for All Students",
      cardBtn: "Read More",
      url: "/case-study/simplifying-math-for-all-students/",
      cardClr: "#FFB800",
      className: "col-md-12 col-6 card-sm",
      xClass: "object-right",
      colSide: "right",
    },
    {
      id: 4,
      bgImg: images.portfolio.PortfolioOhmBg,
      img: <Ohmconnect />,
      cardHeading: "OhmConnect",
      cardIndustry: [
        "GreenTech",
        "Energy & Utilities",
        "Natural Resources",
        "Energy",
      ],
      cardTechnologies: [
        "Python",
        "Flask",
        "ReactJS",
        "Typescript",
        "Webflow",
        "MySQL",
        "Redux",
        "AWS",
      ],
      cardText: "Energy Saving Platform",
      cardBtn: "Read More",
      cardClr: "#546af2",
      className: "col-md-12 col-6 card-sm",
      xClass: "",
      colSide: "left",
      url: "/case-study/energy-saving-platform",
    },
    {
      id: 5,
      bgImg: images.portfolio.PortfolioStradaBg,
      img: <Strada />,
      cardHeading: "Strada",
      cardIndustry: [
        "RealTech",
        "RealEstate",
        "Commercial Property Management",
      ],
      cardTechnologies: [
        "Python",
        "Django",
        "ReactJS",
        "PostgreSQL",
        "AWS",
        "Yardi API",
      ],
      cardText: "Commercial Property Management",
      cardBtn: "Read More",
      cardClr: "#FFB800",
      className: "col-md-12 col-6 card-lg",
      xClass: "object-right",
      colSide: "right",
      url: "/case-study/commercial-property-management/",
    },
    {
      id: 6,
      bgImg: images.portfolio.PortfolioIbdBg,
      img: <Martech />,
      cardHeading: "IBD Sports",
      cardIndustry: ["Advertising and Marketing", "Photography", "Creative"],
      cardTechnologies: [
        "Django",
        "JavaScript",
        "Adobe Creative Cloud",
        "Stripe API",
        "AWS",
        "Bootstrap",
      ],
      cardText:
        "Productivity Solution and Workflow for High-Volume Photography",
      cardBtn: "Read More",
      cardClr: "#546af2",
      className: "col-md-12 col-6 card-sm",
      xClass: "",
      colSide: "left",
      url: "/case-study/productivity-solution-and-workflow-for-high-volume-photography",
    },
    {
      id: 7,
      bgImg: images.portfolio.PortfolioUgamiBg,
      img: <Ugami />,
      cardHeading: "Ugami",
      cardIndustry: ["FinTech", "GameTech", "Gaming"],
      cardTechnologies: [
        "Python",
        "Django",
        "PostgreSQL",
        "Circle API",
        "Unit API",
        "AWS",
      ],
      cardText: "Financial Solution for Gamers",
      cardBtn: "Read More",
      cardClr: "#546af2",
      className: "col-12 card-lg object-size",
      xClass: "object-size",
      colSide: "left",
      url: "/case-study/financial-solution-for-gamers",
    },
    {
      id: 8,
      bgImg: images.portfolio.PortfolioAccretioBg,
      img: <Healthtech />,
      cardHeading: "HeroTrainer",
      cardIndustry: ["HealthCare", "Health", "Fitness"],
      cardTechnologies: [
        "Python",
        "Django",
        "web2py",
        "Bootstrap",
        "PostgreSQL",
        "AWS",
      ],
      cardText: "Fitness Rewards System",
      cardBtn: "Coming soon!",
      cardClr: "#632AB5",
      className: "col-12 card-sm",
      xClass: "",
      colSide: "right",
    },
    {
      id: 9,
      bgImg: images.portfolio.PortfolioPixeletoBg,
      img: <Pixeleto />,
      cardHeading: "Pixeleto",
      cardIndustry: [
        "MarTech",
        "AdTech",
        "Advertising & Marketing",
        "Design Agency",
        "Social Media",
      ],
      cardTechnologies: [
        "Python",
        "AWS Data Lake",
        "AWS Glue",
        "AWS Spark",
        "AWS RedShift",
      ],
      cardText: "Graphics Marketplace",
      cardBtn: "Coming soon!",
      cardClr: "#3C3A50",
      className: "col-md-12 col-6 card-sm",
      xClass: "",
      colSide: "left",
    },
    {
      id: 10,
      bgImg: images.portfolio.PortfolioCommetBg,
      img: <Comet />,
      cardHeading: "Energy Action Partners",
      cardIndustry: [
        "GreenTech",
        "Energy",
        "Natural Resources",
        "Energy Conservation",
      ],
      cardTechnologies: [
        "Django",
        "ReactJS",
        "Typescript",
        "PostgreSQL",
        "Redux",
      ],
      cardText: "Community Energy Toolkit (COMET)",
      cardBtn: "Coming soon!",
      cardClr: "#f65c0d",
      className: "col-md-12 col-6 card-lg",
      xClass: "object-right",
      colSide: "right",
    },
    {
      id: 11,
      bgImg: images.portfolio.PortfoilioPredictBg,
      img: <Vector />,
      cardHeading: "PredictAlly™ by VectorScient",
      cardIndustry: ["Artificial Intelligence", "Machine Learning", "MarTech"],
      cardTechnologies: [
        "Python",
        "scikit-learn",
        "SciPy",
        "GCP BigQuery",
        "NumPy",
        "Pandas",
      ],
      cardText: "Predictive Marketing Cloud",
      cardBtn: "Coming soon!",
      cardClr: "#FECD74",
      className: "col-md-12 col-6 card-sm",
      xClass: "",
      colSide: "right",
    },
    {
      id: 12,
      bgImg: images.portfolio.PortfolioKillBg,
      img: <Klixpert />,
      cardHeading: "KLIXpert",
      cardIndustry: ["HealthCare", "FitnessTech"],
      cardTechnologies: ["Python", "Django", "PostgreSQL", "ReactJS", "Skote"],
      cardText: "Personal Reward System",
      cardBtn: "Coming soon!",
      cardClr: "#D4C10A",
      className: "col-md-12 col-6 card-lg",

      xClass: "",
      colSide: "left",
    },
    {
      id: 13,
      bgImg: images.portfolio.PortfolioRatingBg,
      img: <Rating />,
      cardHeading: "RatingTheRaces",
      cardIndustry: ["Financial Services", "Betting"],
      cardTechnologies: [
        "Django",
        "ReactJS",
        "PostgreSQL",
        "MySQL",
        "AWS",
        "Typescript",
        "Docker",
      ],
      cardText: "Rating & Betting Platform",
      cardBtn: "Coming soon!",
      cardClr: "#10661A",
      className: "col-md-12 col-6 card-sm",
      xClass: "",
      colSide: "right",
    },
    {
      id: 14,
      bgImg: images.portfolio.PortfolioCowokifyBg,
      img: <Coworkify />,
      cardHeading: "Coworkify",
      cardIndustry: ["RealEstate", "Commercial Real Estate", "Coworking"],
      cardTechnologies: [
        "BackboneJS",
        "Pyramid/Python",
        "MySQL",
        "Bootstrap",
        "Paypal API",
      ],
      cardText: "Coworking Space Management Platform",
      cardBtn: "Coming soon!",
      cardClr: "#E53E61",
      className: "col-md-12 col-6 card-sm",
      xClass: "object-left",
      colSide: "left",
    },
    {
      id: 15,
      bgImg: images.portfolio.PortfolioAimBg,
      img: <Aimexpert />,
      cardHeading: "AIM Experts ",
      cardIndustry: ["Automotive", "B2B", "Car Dealership Platform"],
      cardTechnologies: [
        "Python",
        "TensorFlow",
        "OpenCV",
        "Django",
        "MySQL",
        "AWS",
        "Jupyter Notebook",
      ],
      cardText: "Automotive Inventory Management Platform",
      cardBtn: "Coming soon!",
      cardClr: "#f40001",
      className: "col-md-12 col-6 card-sm",
      xClass: "object-left",
      colSide: "left",
    },
  ]
  return (
    <div className="porfolio-bg">
      <Head
        metaTitle="See Our Work: CodeFulcrum's Software Development Portfolio"
        title="See Our Work: CodeFulcrum's Software Development Portfolio"
        desc="Our software development portfolio showcases our end to end software development services. We have a team of experienced engineers and designers who can help you with all your software needs."
      />
      <Hero text="Our portfolio is a showcase of the creative and transformative solutions we've developed for our clients.">
        These Companies Trusted Us And So Can You.
      </Hero>
      <div className="portfolio-main-wrapper">
        <div className="container">
          <React.Suspense fallback={<div />}>
            <PortfolioCards cardData={cardData} />
          </React.Suspense>
        </div>
      </div>
      <Box mt={-1.48}>
        <React.Suspense fallback={<div />}>
          <ContactUs />
        </React.Suspense>
      </Box>
    </div>
  )
}
export default Portfolio
