import React, { useEffect } from "react"
import PortfolioPage from "../components/Portfolio-Page/Content"
import Layout from "../components/Layout"
import { Helmet } from "react-helmet"

export default function Portfolio() {
  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href="https://codefulcrum.com/portfolio/" />
      </Helmet>

      <PortfolioPage />
    </Layout>
  )
}
