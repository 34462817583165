import React from "react"
import "./Hero.scss"
import Button from "../../shared/Button"
export default function Hero({ text, id }) {
  return (
    <div className="portfolio-hero">
      <div className="hero-bg" id={id}>
        <div className="container position-relative">
          <div className="row">
            <div
              className="col-lg-5"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-anchor-placement="bottom-bottom"
            >
              <h1 className="header">
                Building Innovative Softwares For The Future
              </h1>
              {!!text ? (
                <div className="hero-text-portfolio">{text}</div>
              ) : null}
              <div className="button text-capitalize">
                <Button
                  text="GET IN TOUCH"
                  variant="fill"
                  href="/contact"
                  className="text-capitalize"
                />
              </div>
            </div>
            <div className="col-lg-7 mt-sm-0 mt-5">
              <img
                src="/portfolioPage/hero.svg"
                alt="portfolio-hero"
                className="img-fluid"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
