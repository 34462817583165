import React from "react"

function Trackhero() {
  return (
    <img
      src="/portfolioPage/trackhero.webp"
      alt="track-hero"
      loading="lazy"
      style={{ width: "100%", height: "auto" }}
    />
  )
}
function Coworkify() {
  return (
    <img src="/portfolioPage/coworkify.png" alt="cow-workfiy" loading="lazy" />
  )
}
function Driverbee() {
  return (
    <img
      src="/portfolioPage/driverbee.webp"
      alt="driver-bee"
      loading="lazy"
      style={{ width: "100%", height: "auto" }}
    />
  )
}
function MathStarter() {
  return (
    <img
      src="/portfolioPage/mathstarter.png"
      alt="math-starter"
      loading="lazy"
      style={{ width: "100%", height: "auto" }}
    />
  )
}

function Comet() {
  return (
    <img
      src="/portfolioPage/comet.png"
      alt="comet"
      loading="lazy"
      style={{ width: "100%", height: "auto" }}
    />
  )
}
function Healthtech() {
  return (
    <img
      src="/portfolioPage/healthtech.png"
      alt="health-tech"
      loading="lazy"
      style={{ width: "100%", height: "auto" }}
    />
  )
}
function Klixpert() {
  return (
    <img
      src="/portfolioPage/kelixpert.png"
      alt="kelixpert"
      loading="lazy"
      style={{ width: "100%", height: "auto" }}
    />
  )
}
function Martech() {
  return (
    <img
      src="/portfolioPage/IBD.png"
      alt="ibd"
      loading="lazy"
      style={{ width: "100%", height: "auto" }}
    />
  )
}
function Pixeleto() {
  return (
    <img
      src="/portfolioPage/pixeleto.png"
      alt="pixleto"
      loading="lazy"
      style={{ width: "100%", height: "auto" }}
    />
  )
}
function Rating() {
  return (
    <img
      src="/portfolioPage/rating.png"
      alt="rating"
      loading="lazy"
      style={{ width: "100%", height: "auto" }}
    />
  )
}
function Strada() {
  return (
    <img
      src="/portfolioPage/strada.png"
      alt="strada"
      loading="lazy"
      style={{ width: "100%", height: "auto" }}
    />
  )
}
function Ugami() {
  return (
    <img
      src="/portfolioPage/ugami.png"
      alt="ugami"
      loading="lazy"
      style={{ width: "100%", height: "auto" }}
    />
  )
}
function Vector() {
  return (
    <img
      src="/portfolioPage/vector.png"
      alt="vector"
      loading="lazy"
      style={{ width: "100%", height: "auto" }}
    />
  )
}
function Aimexpert() {
  return (
    <img
      src="/portfolioPage/aimexperts.webp"
      alt="aimexperts"
      loading="lazy"
      style={{ width: "100%", height: "auto" }}
    />
  )
}
function Ohmconnect() {
  return (
    <img
      src="/portfolioPage/ohmconnect.webp"
      alt="ohm-connect"
      loading="lazy"
      style={{ width: "100%", height: "auto" }}
    />
  )
}
export {
  Trackhero,
  Coworkify,
  Driverbee,
  MathStarter,
  Comet,
  Healthtech,
  Klixpert,
  Martech,
  Pixeleto,
  Rating,
  Strada,
  Ugami,
  Vector,
  Aimexpert,
  Ohmconnect,
}
